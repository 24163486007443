<template>
    <v-card>
        <v-card-title>
            <span class="text-h6">{{ $t('labels.lost_item') }}</span>
            <v-spacer></v-spacer>
            <v-btn small color="success" :disabled="isDisableBtnSubmit" outlined @click="submitLostItem" class="mr-2">
                {{ $t('labels.confirm') }}
            </v-btn>
            <v-btn small color="error" outlined @click="closeDialog">
                {{ $t('labels.close') }}
            </v-btn>
        </v-card-title>
        <v-card-text>
            <v-row dense>
                <v-col cols="12">
                    <v-text-field class="c-input-xs" v-model="note" type="text" :label="$t('labels.note')" dense outlined
                        clearable hide-details></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-btn block v-for="(goods, key) in items" :key="`g_${key}_${goods.id_goods}`" class="mb-3"
                        @click="selectGoods(goods)" :color="goodsSelected === goods.id_goods ? 'primary' : 'default'">
                        <div class="d-flex align-center justify-space-between w-100">
                            <span>{{ $t('labels.sku') }}: {{ goods.sku }}</span>
                            <span>{{ goods.selecteds.length }}/{{ goods.remaining_quantity }}</span>
                        </div>
                    </v-btn>
                </v-col>
                <v-col cols="8">
                    <div v-for="(goods, key) in items" :key="`g_${key}_${goods.id_goods}`">
                        <v-simple-table class="table-padding-2" v-if="goodsSelected === goods.id_goods">
                            <template v-slot:default>
                                <thead>
                                    <tr class="tr-h-36px">
                                        <th role="columnheader" class="text-center">UID | IMEI</th>
                                        <th role="columnheader" class="text-center">Khả dụng</th>
                                        <th role="columnheader" class="text-center" style="width: 75px"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in goods.items" :key="`i_${index}_${item.id}`"
                                        class="tr-h-36px text-center">
                                        <td>{{ item.barcode || `${goods.sku}@${item.uid}` }}</td>
                                        <td>{{ formatDateTime(item.available_at) }}</td>
                                        <td class="text-center vertical-align-middle">
                                            <div class="d-flex align-center justify-center">
                                                <v-checkbox v-model="goods.selecteds" multiple class="mt-0 ml-1 pt-0"
                                                    :value="item.id" hide-details
                                                    :disabled="disableSelected(goods, item)"></v-checkbox>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </div>
                </v-col>
            </v-row>

        </v-card-text>
    </v-card>
</template>
  
<script>
import { httpClient } from "@/libs/http";
export default {
    name: "FindGoodsLostItem",
    components: {

    },
    props: {
        item: {
            type: Object,
            default: () => { }
        },
    },
    data: () => ({
        items: [],
        isLoading: false,
        note: null,
        goodsSelected: null
    }),
    computed: {
        remainingQuantity() {
            return goods => goods.remaining_quantity - goods.selecteds.length
        },
        disableSelected() {
            return (goods, item) => this.remainingQuantity(goods) <= 0 && !goods.selecteds.includes(item.id)
        },
        isDisableBtnSubmit() {
            let disabled = false
            for (let i = 0; i < this.items.length; i++) {
                const goods = this.items[i];
                if (this.remainingQuantity(goods) !== 0) {
                    disabled = true
                }
            }
            return disabled
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        closeDialog() {
            this.$emit('closeDialog')
        },
        selectGoods(goods) {
            this.goodsSelected = goods.id_goods
        },
        async getList() {
            const { data } = await httpClient.post('/find-goods/v1/manager-get-items-for-lost', {
                id_goods_issue_detail: this.item.id_goods_issue_detail,
                id_basket: this.item.id_basket,
            })
            this.items = [...data].map(item => ({
                ...item,
                selecteds: []
            }))
            this.selectGoods(this.items[0])
        },
        async submitLostItem() {
            if (this.isLoading) {
                this.$vToastify.warning(this.$t('messages.loading'));
                return false
            }
            this.isLoading = true
            try {
                await httpClient.post('/find-goods/v1/manager-create-lost-item-request', {
                    id_goods_issue_detail: this.item.id_goods_issue_detail,
                    id_basket: this.item.id_basket,
                    items: this.items,
                    note: this.note
                })
                this.isLoading = false
                this.closeDialog()
            } catch (e) {
                this.isLoading = false
                const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
                this.$vToastify.error(errMsg)

            }
        },
    }
}
</script>
  
<style scoped></style>
  